<template>
  <Disclosure as="nav" class="bg-Primary-800" v-slot="{ open }">
    <div class="flex items-center justify-between px-4 py-2.5 md:px-0 md:py-4">
      <div class="content fluid-container-custom w-full mx-auto flex justify-between items-center">
        <NuxtLink to="https://europeandemocracyhub.epd.eu">
            <img class="h-12 md:h-20" src="/logo-EDH-wit.svg" alt="EDH" />
        </NuxtLink>
        <div class="hidden md:flex items-center text-white font-normal">
            <template
            v-for="menuNode in menuNodes"
            :key="menuNode.title"
            >
            <NuxtLink
                :to="menuNode.href"
                class="heading hover:text-Primary-200 mr-6"
                :class="
                route.path.includes(menuNode.href)
                    ? 'active text-primary-200'
                    : ''
                "
                >{{ menuNode.title }}</NuxtLink
            >
            </template>
        </div>
      </div>
      <div class="-mr-2 flex md:hidden">
        <!-- Mobile menu button -->
        <DisclosureButton class="relative inline-flex items-center justify-center p-2 text-white">
            <span class="absolute -inset-0.5" />
              <span class="sr-only">Open main menu</span>
              <IconsHamburger v-if="!open" class="block h-8 w-8" aria-hidden="true" />
              <XMarkIcon v-else class="block h-8 w-8" aria-hidden="true" />
        </DisclosureButton>
      </div>
    </div>
    <DisclosurePanel class="md:hidden bg-Primary-800 absolute w-full h-full">
      <div class="space-y-1 px-10 pb-3 pt-2 text-white">
        <DisclosureButton
          v-for="menuNode in menuNodes"
          :key="menuNode.title"
          as="a"
          :href="menuNode.href"
          class="block heading py-2 hover:text-Primary-200 mr-6"
          :class="
          route.path.includes(menuNode.href)
              ? 'active text-primary-200'
              : ''
          "
          >{{ menuNode.title }}</DisclosureButton
        >
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { useRoute } from '#imports'
const route = useRoute()
const menuNodes = [
{
  title: 'Homepage',
  href: 'https://europeandemocracyhub.epd.eu/data-tools/'
},
// {
//   title: 'Publications',
//   href: 'https://europeandemocracyhub.epd.eu/publications/'
// },
// {
//   title: 'Events',
//   href: 'https://europeandemocracyhub.epd.eu/events/'
// },
// {
//   title: 'Newsletter',
//   href: 'https://europeandemocracyhub.epd.eu/#newsletter-banner'
// }
]
</script>