<template>
  <div class="py-4  bg-Primary-50 text-Primary-800">
    <div class="fluid-container-custom flex justify-between items-cente">
      <h1 class="text-lg font-bold"> <nuxt-link to="/"> European Democracy Support Funding </nuxt-link></h1>
      <div class="flex items-center gap-2">
        <nuxt-link
          to="/flow"
          class="btn"
          :class="{
            inactive: selectedPage !== 'flow',
            primary: selectedPage === 'flow',
          }"
        >
          Spending Flow
        </nuxt-link>
        <nuxt-link
        to="/geo"
          class="btn"
          :class="{
            inactive: selectedPage !== 'geo',
            primary: selectedPage === 'geo',
          }"
        >
          Geographic
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
const route = useRoute();

const selectedPage = computed(() => {
  return route.name;
});
</script>

<style lang="scss" scoped></style>
