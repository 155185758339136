<template>
  <header>
    <NavigationHeaderMainHeader />
    <NavigationHeaderSubHeader v-if="route.name !== 'index' && route.name !== 'mobile' " />
  </header>
</template>
<script setup>
import { useRoute } from "vue-router";
const route = useRoute();

</script>